<template>
  <div>
    <div class="row title">企业管理优势</div>

    <div class="row inverse-background">
      <div class="bold-center">企业管理</div>
      <div class="vs bold-center">
        VS
      </div>
      <div class="bold-center">传统机构</div>
    </div>

    <div class="advantage-border">
      <div class="row" v-for="i in 10" :key="i">
        <div class="first">{{ vs.zfind[i] }}</div>
        <div class="vs">
          {{ vs.item[i] }}
        </div>
        <div class="second">{{ vs.other[i] }}</div>
      </div>
    </div>
  </div>
</template>
<script>

// Home.vue
export default {
  name: "OfficialAdvantage",
  data() {
    return {
      vs: {
        "zfind": [
          "企业管理",
          "精准记账",
          "控制提示风险",
          "实时响应",
          "提示潜在风险",
          "税务策划方案、落地实施",
          "税务局沟通、专项申请",
          "可提供内部审计的服务",
          "初创企业的财务体系构建协助",
          "差异化兼并与收购方案",
          "投融资、预备资金配置"
        ],
        "item": [
          "VS",
          "专业",
          "安全",
          "效率",
          "预告",
          "建议",
          "沟通",
          "审计",
          "构建",
          "兼并",
          "投资"
        ],
        "other": [
          "传统机构",
          "账目粗略",
          "安全性低",
          "响应慢",
          "无潜在风险意识",
          "只能提供税务建议无法落地",
          "缺乏沟通",
          "只提供外部审计报告",
          "无财务体系构建",
          "无",
          "无"
        ]
      },
    }
  },
  computed: {
    username() {
      // We will see what `params` is shortly
      return this.$route.params
    },
  },
  methods: {

  },
  components: {
  },
}
</script>
<style scoped>
.advantage-border {
  padding: 0px 10px;
}

.inverse-background {
  background-color: #343434;

}

.row {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  padding: 6px 2px;
}
.title {
  font-weight: bold;
}

.bold-center {
  flex: 3;
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
}

.first {
  flex: 3;
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  text-align: left;
}
.vs {
  flex: 1;
  color: cornflowerblue;
}
.second {
  flex: 3;
  display: flex;
  justify-content: flex-start;
  text-align: left;
}
</style>