<template>
  <van-nav-bar :title="title">
    <template #left>
      <van-icon name="ellipsis" size="18"/>
<!--      <van-icon name="ellipsis" size="18" @click="subMenuVisible = !subMenuVisible"/>-->
    </template>
    <template #right>
      <van-icon name="service-o" size="18" @click="onDialEvent"/>
    </template>
  </van-nav-bar>
  <van-popup
    v-model:show="subMenuVisible"
    position="left"
    :style="{ width: '90%', height: '100%' }"
  >
    <van-cell-group>
      <van-cell title="首页" value="" icon="location-o" @click="onHomePageEvent" />
    </van-cell-group>
    <van-cell-group>
      <van-cell title="发展历程" value="" icon="location-o" @click="onHistoryPageEvent"/>
      <van-cell title="对比优势" value="" icon="location-o" @click="onAdvantagePageEvent"/>
    </van-cell-group>
    <van-cell-group title="服务&产品">
      <van-cell title="基础记账服务" value="" icon="location-o" @click="onProductEvent(1)"/>
      <van-cell title="外派财务服务" value="" icon="location-o" @click="onProductEvent(2)"/>
      <van-cell title="专项财务服务" value="" icon="location-o" @click="onProductEvent(3)"/>
      <van-cell title="高级财税服务" value="" icon="location-o" @click="onProductEvent(4)"/>   
    </van-cell-group>
    <van-cell-group>
      <van-cell title="关于我们" value="" icon="location-o" @click="onAboutMePageEvent" />
    </van-cell-group>
  </van-popup>

  <router-view></router-view>
  <van-tabbar v-model="active" @change="onTabChange">
    <van-tabbar-item icon="home-o" >首页</van-tabbar-item>
    <van-tabbar-item icon="friends-o">服务客户</van-tabbar-item>
    <van-tabbar-item icon="phone-o">联系我们</van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { Dialog } from 'vant';

export default {
  name: 'App',
  data() {
    return {
      active: 0,
      title: '首页',
      subMenuVisible: false,
      phone: '13818682161'
    };
  },
  components: {
  },
  watch: {
    $route(to, from) {
      console.log(to)
      console.log(from)
      // document.title = to.meta.title || '首页';
      this.title = to.meta.title || '上海砾芮科技公司';
      document.title = '上海砾芮科技公司';
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.metaInfo.title,
        meta: [
            { name: "keywords", content: this.$route.meta.metaInfo.keywords },
            { name: "description", content: this.$route.meta.metaInfo.description },
        ]
    }
  },
  methods: {
    onDialEvent() {
      Dialog({
        title: '提示',
        message: `确定拨打电话：${this.phone}吗`,
        confirmButtonText: '确定',
        showConfirmButton: true,
        cancelButtonText: '取消',
        showCancelButton: true,
      }).then(() => {
        // on close
        console.log('call out')
        const phoneNumber = `tel:${this.phone}`;
        window.location.href = phoneNumber;
      })
      .catch(() => {
        // on cancel
        console.log('cancel')
      });
    },
    onHistoryPageEvent() {
      this.$router.push('/history')
      this.subMenuVisible = false
    },
    onAdvantagePageEvent() {
      this.$router.push('/advantage')
      this.subMenuVisible = false
    },
    onHomePageEvent() {
      this.$router.push('/')
      this.subMenuVisible = false
    },
    onAboutMePageEvent() {
      this.$router.push('/about')
      this.subMenuVisible = false
    },
    onProductEvent(productIndex) {
      console.log(productIndex)
      this.$router.push(`/product/${productIndex}`)
      this.subMenuVisible = false
    },
    onTabChange(index) {
      if (index === 0) {
        this.$router.push('/')
      }
      if (index === 1) {
        this.$router.push('/customer')
      }
      if (index === 2) {
        Dialog({
        title: '提示',
        message: `确定拨打电话：${this.phone}吗`,
        confirmButtonText: '确定',
        showConfirmButton: true,
        cancelButtonText: '取消',
        showCancelButton: true,
      }).then(() => {
        // on close
        console.log('call out')
        const phoneNumber = `tel:${this.phone}`;
        window.location.href = phoneNumber;
      })
      .catch(() => {
        // on cancel
        console.log('cancel')
      });
      }
    }
  },
}


</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
