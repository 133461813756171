<template>
  <div>
    <div style="width: 100%">
      <van-image
          :width="clientWidth"
          src="https://topshzc.top/assets/main_ads.png"
      />
    </div>
  </div>
</template>
<script>

// Home.vue
export default {
  name: "OfficialHome",
  data() {
    return {
      clientWidth: document.body.innerWidth,
    }
  },
  computed: {

  },
  mounted() {
    window.onresize = () => {
      return this.clientWidth = document.body.innerWidth
    }
  },
  methods: {

  },
  components: {
  },
}
</script>
<style scoped>
.advantage-border {
  padding: 0px 10px;
}

.inverse-background {
  background-color: #343434;

}

.row {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  padding: 6px 2px;
}
.title {
  font-weight: bold;
}

.bold-center {
  flex: 3;
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
}

.first {
  flex: 3;
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  text-align: left;
}
.vs {
  flex: 1;
  color: cornflowerblue;
}
.second {
  flex: 3;
  display: flex;
  justify-content: flex-start;
  text-align: left;
}
</style>