<template>
  <div>
    <div>
        <van-steps direction="vertical" :active="active" @click-step="onStepClickEvent">
            <van-step>
                <p class="year">2024</p>
                <p class="info">打造企业服务云商城，形成初具规模和一定影响力的企业服务行业联盟。</p>
            </van-step>
            <van-step>
                <p class="year">2023</p>
                <p class="info">创新科技赋能创业者、企业服务机构、政府园区。整合政府及企业服务机构资源。</p>
            </van-step>
            <van-step>
                <p class="year">2022</p>
                <p class="info">助力临港园区招商，成为临港金融板块招商的推动者。 打造自主研发平台并成为高新技术企业。</p>
            </van-step>
            <van-step>
                <p class="year">2021</p>
                <p class="info">人才引进、积分落户、企业、助力大学生创业培训、外省多家园区达成科技型企业孵化合作</p>
            </van-step>
            <van-step>
                <p class="year">2020</p>
                <p class="info">与上海35家银行签约合作战略方针、央企投融资、IPO、VC、PE、B、C轮</p>
            </van-step>
            <van-step>
                <p class="year">2019</p>
                <p class="info">紫勤会计事务所挂牌成立</p>
            </van-step>
            <van-step>
                <p class="year">2018</p>
                <p class="info">与百旺达成业务合作、浙江、江苏分公司成立</p>
            </van-step>
            <van-step>
                <p class="year">2017</p>
                <p class="info">实现“互联 网+会计服务”转型发展</p>
            </van-step>
            <van-step>
                <p class="year">2016</p>
                <p class="info">获上海市经信委中小企业公共服务平台</p>
            </van-step>
            <van-step>
                <p class="year">2015</p>
                <p class="info">与北京商标局有关部门达成长期战略合作、香港分公司成 立</p>
            </van-step>
            <van-step>
                <p class="year">2014</p>
                <p class="info">为企业提供 “一站式” 财税服务与上海多家园区合作</p>
            </van-step>
            <van-step>
                <p class="year">2013</p>
                <p class="info">成立</p>
            </van-step>
        </van-steps>

    </div>
  </div>
</template>
<script>
export default {
  name: "OfficialHistory",
  data() {
    return {
      active: 0,
    };
  },
  computed: {

  },
  methods: {
    onStepClickEvent(index) {
        this.active = index
    }
  },
}
</script>
<style scoped>
p {
    text-align: left;
}
.year {
    color: #333333;
    font-weight: bold;
    font-size: 16px;
}

.info {
    font-size: 14px;
}
</style>